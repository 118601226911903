<template>
  <div class="the-header">
    <div
      class="web-header flex px-24 py-4 items-center justify-between"
      :class="{ 'has-shadow': isSearch }"
    >
      <div class="flex-icon">
        <img :src="picture" class="h-16 cursor-pointer" alt="logo" @click="goToIndex">
      </div>
      <div class="flex-one option-button-section flex items-center text-black text-xl">
        <div class="flex-one flex items-center mx-4 cursor-pointer" @click="goToWebSite">
          <svg-icon
            name="ic_help"
            original
            class="w-4"
          />
          <div class="help ml-3 font-normal">{{ $t('helpHeader') }}</div>
        </div>
        <div class="flex-one flex items-center mx-4 cursor-pointer" @click="goToLink('faq')">
          <svg-icon
            name="ic_faq"
            original
            class="w-4"
          />
          <div class="help ml-3 font-normal">{{ $t('faqHeader') }}</div>
        </div>
        <div class="flex-one flex items-center mx-4 cursor-pointer" @click="goToContacts">
          <svg-icon
            name="ic_phone"
            original
            class="w-4"
          />
          <div class="help ml-3 font-normal">{{ $t('contactHeader') }}</div>
        </div>
        <div class="login flex-one flex items-center mx-4">
          <button-base
            @click.native="openUrl"
            :label="$t('loginHeader')"
            :show-icon="true"
            name-icon="ic_user"
          />
        </div>
        <div v-if="isConnect" class="flex-one flex items-center mx-4">
          <div class="connect py-3 px-6 rounded-lg font-normal flex items-center justify-center cursor-pointer">
            <div class="border-2 border-white rounded-full p-2 mr-2">
              <svg-icon
                name="ic_user"
                original
                class="w-4 h-4"
              />
            </div>
            <h4 class="font-bold text-white">
              {{ $t('mySpaceHeader') }}
            </h4>
            <svg-icon
              name="ic_open_down"
              original
              class="w-4 h-4 ml-2"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-header h-24">
      <div class="flex-container p-4 flex">
        <div class="logo-mobile">
          <img :src="picture" class="h-12 cursor-pointer" alt="logo" @click="goToIndex">
        </div>
        <div class="flex-one flex-grow items-center flex justify-end ">
          <div class="burger" v-if="!showMobileMenu" @click="showMobileMenu = !showMobileMenu">
            <svg-icon
              name="ic_menu"
              original
              class="w-8 h-8"
            />
          </div>
          <div class="close-burger" v-else @click="showMobileMenu = !showMobileMenu">
            <svg-icon
              name="ic_close"
              original
              class="w-6 h-6"
            />
          </div>
        </div>
      </div>
      <div class="w-full mobile-menu-items shadow-lg p-4 text-black" :class="{ 'mobile-menu-items-active': showMobileMenu}">
        <div class="debt w-full z-50 flex justify-center items-center">
          <div class="flex flex-col justify-center">
            <div class="flex-one flex items-center justify-center mx-4 my-6 cursor-pointer" @click="goToWebSite">
              <svg-icon
                name="ic_help"
                original
                class="w-4"
              />
              <div class="help ml-3 font-normal">{{ $t('helpHeader') }}</div>
            </div>
            <div class="flex-one flex items-center justify-center mx-4 my-6 cursor-pointer" @click="goToLink('faq')">
              <svg-icon
                name="ic_faq"
                original
                class="w-4"
              />
              <div class="help ml-3 font-normal">{{ $t('faqHeader') }}</div>
            </div>
            <div class="flex-one flex items-center justify-center mx-4 my-6 cursor-pointer" @click="goToContacts">
              <svg-icon
                name="ic_phone"
                original
                class="w-4"
              />
              <div class="help ml-3 font-normal">{{ $t('contactHeader') }}</div>
            </div>
            <div class="login flex-one flex items-center justify-center mx-4 my-6">
              <button-base
                @click.native="openUrl"
                :label="$t('loginHeader')"
                :show-icon="true"
                name-icon="ic_user"
              />
            </div>
            <div v-if="isConnect" class="flex-one flex items-center justify-center mx-4 mt-6">
              <div class="connect py-3 px-6 rounded-lg font-normal flex items-center justify-center cursor-pointer">
                <div class="border-2 border-white rounded-full p-2 mr-2">
                  <svg-icon
                    name="ic_user"
                    original
                    class="w-4 h-4"
                  />
                </div>
                <h4 class="font-bold text-white">
                  {{ $t('mySpaceHeader') }}
                </h4>
                <svg-icon
                  name="ic_open_down"
                  original
                  class="w-4 h-4 ml-2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import image from '../assets/images/img_logo.webp'
import ButtonBase from './helper/add/button-base'
export default {
  name: 'the-header',
  components: { ButtonBase },
  data () {
    return {
      picture: image,
      showMobileMenu: false
    }
  },
  props: {
    isSearch: {
      type: Boolean,
      default: false
    },
    isConnect: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    goToIndex () {
      this.$router.push({ path: '/' })
    },
    goToLink (page) {
      this.$router.push({ path: '/' + page })
    },
    goToContacts () {
      window.open('https://www.atlantiqueassurances.bj/nous-contacter/', '_blank')
    },
    goToWebSite () {
      window.open('https://www.atlantiqueassurances.bj/', '_blank')
    },
    openUrl () {
      window.open('https://app.aab.bj/')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .the-header {
    filter: drop-shadow(0px 4px 50px rgba(0, 0, 0, 0.1));
    box-shadow: -5px 0px 18px rgba(0, 0, 0, 0.15);
  }
  .svg-fill {
    fill: transparent;
  }
  .logo_separator {
    border-color: $aab_green;
  }
  .connect {
    background: $aab_blue;
  }
  .login::v-deep {
    .button-base {
      .page-button-real {
        border-radius: 10px;
        padding-top: 1rem;
        padding-bottom: 1rem;
        h4 {
          font-weight: 500;
          font-size: 18px;
        }
      }
    }
  }
  .has-shadow {
    box-shadow: 0 1px 1.5px 0 rgba(0,0,0,.12), 0 1px 1px 0 rgba(0,0,0,.24);
  }

  .search-icon-section {
    background-color: $aab_blue;
  }

  .action {
    color: $black;
    text-decoration: none;
  }
  .login-button {
    background-color: $aab_blue;
  }
  .burger {
    cursor: pointer;
    .line {
      height: .2rem;
      width: 3rem;
      background-color: $aab_blue;
    }
  }

  .close-burger  {
    cursor: pointer;
    .line {
      height: .2rem;
      width: 3rem;
      background-color: $aab_blue;
    }
    .line:first-child {
      transform: rotate(-40deg);
    }
    .line:last-child {
      transform: rotate(40deg);
    }
  }
  .mobile-menu-items {
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.26s ease;
  }
  .mobile-menu-items-active {
    transform: scaleY(1);
    background-color: white;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  .mobile-header {
    display: none;
  }
  @media only screen and (max-width: 600px) {
    .mobile-header {
      display: block;
      z-index: 10000;
      background-color: white;
    }
    .web-header {
      display: none;
    }
    .search-section {
      display: none;
    }
    .help {
      font-size: 1.5rem;
    }
    .button-base::v-deep {
      .page-button-real {
        .svg-fill {
          width: 1.25rem;
          height: 1.25rem;
        }
        h4 {
          font-size: 1.5rem;
        }
      }
    }
  }
  @media only screen and (min-width: 601px) and (max-width: 768px) {
    .mobile-header {
      display: block;
      z-index: 10000;
      background-color: white;
    }
    .web-header {
      display: none;
    }
    .search-section {
      display: none;
    }
    .help {
      font-size: 1.5rem;
    }
    .button-base::v-deep {
      .page-button-real {
        .svg-fill {
          width: 1.25rem;
          height: 1.25rem;
        }
        h4 {
          font-size: 1.5rem;
        }
      }
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .mobile-header {
      display: block;
      z-index: 10000;
      background-color: white;
    }
    .web-header {
      display: none;
    }
    .logo-mobile {
      img {
        height: 4rem;
      }
    }
    .search-section {
      display: none;
    }
    .help {
      font-size: 1.5rem;
    }
    .button-base::v-deep {
      .page-button-real {
        .svg-fill {
          width: 1.25rem;
          height: 1.25rem;
        }
        h4 {
          font-size: 1.5rem;
        }
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    .web-header {
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }
</style>
