<template>
  <div class="oremi-aab">
    <div class="begin w-full flex justify-center items-center py-8">
      <div
        class="debt flex flex-col justify-center"
        data-aos="fade-up"
        data-aos-once="true"
        data-aos-duration="1500"
        data-aos-anchor-placement="top-center"
      >
        <div class="text-4xl text-center font-semibold mb-4">
          {{ $t('oremiInPoint') }}
        </div>
        <div class="bloc flex justify-center flex-wrap mt-8">
          <div
            v-for="(item, index) in oremis"
            :key="index"
            data-aos="fade-up"
            data-aos-once="true"
            data-aos-duration="1500"
            class="bloc-content  leading-relaxed border rounded-2xl mr-4 mb-10 w-1/4"
          >
            <div class="content-11  p-6">
              <div class="icon mb-4">
                <svg-icon
                  :name="item.icon"
                  original
                  class="w-16 h-16"
                />
              </div>
              <div class="bloc-title font-semibold text-black text-2xl mb-4">{{ item.title }}</div>
              <div class="bloc-description text-xl mb-6">{{ item.description }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'oremi-aab',
  data () {
    return {
      oremis: [
        {
          icon: 'ic_signup',
          title: this.$t('signUp'),
          description: this.$t('identifyYou')
        },
        {
          icon: 'ic_estimation',
          title: this.$t('estimation'),
          description: this.$t('sendInfos')
        },
        {
          icon: 'ic_subscription',
          title: this.$t('subscription'),
          description: this.$t('autoMoto')
        },
        {
          icon: 'ic_payment',
          title: this.$t('inlinePayment'),
          description: this.$t('byMomo')
        },
        {
          icon: 'ic_declaration',
          title: this.$t('sinisterDeclaration'),
          description: this.$t('makeYourDeclaration')
        },
        {
          icon: 'ic_declaration',
          title: this.$t('realtimeAssistance'),
          description: this.$t('sendUs')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .bloc-content {
    &:hover, &:focus {
      border-color: $aab_blue;
      // border-width: 1px;
    }
  }
  @media only screen and (max-width: 600px) {
    .bloc-content {
      width: auto;
      margin-right: 0;
    }
  }
  @media only screen and (min-width: 601px) and (max-width: 768px) {
    .bloc-content {
      width: auto;
      margin-right: 0;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .bloc-content {
      width: 45%;
      margin-right: 1rem;
    }
  }
  @media only screen and (max-width: 1024px) {
    .begin {
      padding-right: 1rem;
      padding-left: 1rem;
    }
    .debt {
      width: 100%;
    }
  }
</style>
