<template>
  <div class="chrono-insurance">
    <div class="begin px-24 py-16 mt-12 flex items-center justify-between">
      <div
        class="first w-1/2"
        data-aos="fade-down"
        data-aos-once="true"
        data-aos-duration="1500"
        data-aos-anchor-placement="top-center"
      ></div>
      <div
        class="second w-1/2 leading-relaxed"
        data-aos="fade-up"
        data-aos-once="true"
        data-aos-duration="1500"
        data-aos-anchor-placement="top-center"
      >
        <div class="title text-black text-4xl font-semibold mb-8">{{ $t('chronoInsurance') }}</div>
        <div class="details">
          <div
            v-for="(item, index) in chronos"
            :key="index"
            class="details-content flex items-baseline my-4"
          >
            <div class="icon mr-6">
              <svg-icon
                :name="item.icon"
                original
                class="w-6 h-6"
              />
            </div>
            <div class="description text-2xl">{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'chrono-insurance',
  data () {
    return {
      chronos: [
        {
          icon: 'ic_selection',
          content: this.$t('noWasteTime')
        },
        {
          icon: 'ic_selection',
          content: this.$t('isQuoteOk')
        },
        {
          icon: 'ic_selection',
          content: this.$t('paymentMomo')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .first {
    height: 30rem;
    background-image: url("../../../assets/images/img_auto.webp");
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
  }
  .svg-fill {
    fill: transparent;
  }
  @media only screen and (max-width: 600px) {
    .begin {
      flex-wrap: wrap;
    }
    .first, .second {
      width: 100%;
    }
  }
  @media only screen and (max-width: 1024px) {
    .begin {
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }
</style>
