<template>
  <div class="simplify-life">
    <div class="begin w-full flex justify-center items-center py-8">
      <div
        class="debt-bloc flex flex-col justify-center w-3/4"
        data-aos="fade-up"
        data-aos-once="true"
        data-aos-duration="1500"
        data-aos-anchor-placement="top-center"
      >
        <div class="title text-4xl text-center font-semibold text-white leading-relaxed mb-8" v-html="$t('simplifyYourLife')"></div>
        <div class="description flex items-center mt-6">
          <div class="first w-1/2 mr-3">
            <div class="bloc-1 flex items-center justify-between mb-4">
              <div
                v-for="(item, index) in simply"
                :key="index"
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-duration="1500"
                class="sous-bloc-1 rounded-2xl p-6 text-black w-1/2 mx-2"
                :style="{ backgroundColor: item.background }"
              >
                <div class="icon">
                  <svg-icon
                    :name="item.icon"
                    original
                    class="w-10 h-10"
                  />
                </div><br>
                <div class="sous-title text-xl mt-2">{{ item.content }}</div><br>
                <div class="sous-description text-black font-semibold text-3xl mb-4" v-html="item.description"></div>
              </div>
            </div>
            <div
              class="bloc-2 rounded-2xl p-6 text-white"
              data-aos="fade-up"
              data-aos-once="true"
              data-aos-duration="1500"
            >
              <div class="icon">
                <svg-icon
                  name=""
                  original
                  class="w-6 h-6"
                />
              </div><br>
              <div class="sous-title text-xl font-normal">{{ $t('chooseCalm') }}</div><br>
              <div class="sous-description font-semibold text-3xl mb-4" v-html="$t('yourInsuranceCover')"></div>
            </div>
          </div>
          <div
            class="second w-1/2 ml-3 rounded-2xl"
            data-aos="fade-up"
            data-aos-once="true"
            data-aos-duration="1500"
            data-aos-anchor-placement="top-center"
          >
            <div class="second-bloc w-full flex justify-center items-center py-12 rounded-2xl">
              <div class="flex flex-col justify-center p-6">
                <div class="title text-3xl text-center font-semibold leading-relaxed mb-8" v-html="$t('ready')"></div>
                <div class="sous-title font-normal leading-relaxed text-xl text-center mb-8" v-html="$t('byePaper')"></div><br>
                <div class="bloc-2-button text-center text-xl font-medium text-white rounded-full cursor-pointer py-4 px-16 mb-4">
                  {{ $t('estimationPrime') }}
                </div><br>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'simplify-life',
  data () {
    return {
      simply: [
        {
          background: '#E8F9FF',
          icon: 'ic_clock',
          content: this.$t('realizeYour'),
          description: this.$t('quoteInsurance')
        },
        {
          background: '#FFF3EF',
          icon: 'ic_bookmark',
          content: this.$t('someClicks'),
          description: this.$t('subscribeContract')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .sous-bloc-1 {
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  .bloc-2 {
    background: $aab_blue_cover;
  }
  .second {
    background: linear-gradient(#CAF2FF, #5BB4DE, #FFB69E);
    padding: 3px;
  }
  .second-bloc {
    background: $white;
    // padding: 2rem;
  }
  .bloc-2-button {
    background: $aab_blue;
    width: fit-content;
    margin: auto;
  }
  .sous-title::v-deep {
    font-weight: 100;
    br {
      display: none;
    }
  }
  @media only screen and (max-width: 600px) {
    .debt-bloc {
      width: 100%;
    }
    .description {
      flex-wrap: wrap;
    }
    .sous-description {
      font-size: 1.5rem;
    }
    .first, .second {
      width: 100%;
      margin-bottom: 1rem;
      margin-left: 0;
      margin-right: 0;
    }
  }
  @media only screen and (min-width: 601px) and (max-width: 768px) {
    .debt-bloc {
      width: 100%;
    }
    .description {
      flex-wrap: wrap;
    }
    .sous-description {
      font-size: 1.5rem;
    }
    .first, .second {
      width: 100%;
      margin-bottom: 1rem;
      margin-left: 0;
      margin-right: 0;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .debt-bloc {
      width: 100%;
    }
    .description {
      flex-wrap: wrap;
    }
    .sous-description {
      font-size: 1.5rem;
    }
    .first, .second {
      width: 100%;
      margin-bottom: 1rem;
      margin-left: 0;
      margin-right: 0;
    }
  }
  @media only screen and (max-width: 1024px) {
    .begin {
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }
</style>
