<template>
  <div class="button-base">
    <button
      class="btn page-button-real py-3 px-6 rounded-lg font-normal flex items-center justify-center"
      :style="{backgroundColor: background, color: fontcolor}"
      :class="{'border-dotted': borderDot,'border': !borderDot}"
    >
      <svg-icon
        v-if="showIcon"
        :name="nameIcon"
        original
        class="w-4 h-4 mr-2"
      />
      <h4
        v-if="!isLoading"
        class="font-normal"
      >
        {{ label }}
      </h4>
      <h6 v-if="isLoading">
        <clip-loader :color="color" />
      </h6>
    </button>
  </div>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ScaleLoader'
export default {
  name: 'button-base',
  components: {
    ClipLoader
  },
  props: {
    label: {
      type: String,
      default: 'Button'
    },
    nameIcon: {
      type: String
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    // TODO HERE IF HE WANT PREVIOUS COLOR
    background: {
      type: String,
      default: '#006FB1'
    },
    fontcolor: {
      type: String,
      default: '#FFFFFF'
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    borderDot: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      color: '#FFFFFF'
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .border-dotted {
    border: solid #006FB1 0;
  }
</style>
