<template>
  <div class="home-page">
    <div class="header fixed z-50 w-full bg-white">
      <the-header/>
    </div>
    <div class="body pt-16">
      <no-insurance/>
      <chrono-insurance/>
      <simplify-life/>
      <oremi-aab/>
      <all-insurance/>
    </div>
    <div class="footer">
      <the-footer/>
    </div>
  </div>
</template>

<script>
import TheHeader from '../../components/the-header'
import NoInsurance from '../../components/helper/home/no-insurance'
import ChronoInsurance from '../../components/helper/home/chrono-insurance'
import SimplifyLife from '../../components/helper/home/simplify-life'
import OremiAab from '../../components/helper/home/oremi-aab'
import TheFooter from '../../components/the-footer'
import AllInsurance from '../../components/helper/home/all-insurance'
export default {
  name: 'index',
  components: { AllInsurance, TheFooter, OremiAab, SimplifyLife, ChronoInsurance, NoInsurance, TheHeader }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .svg-fill {
    fill: transparent;
  }
  .body {
    font-weight: 100;
  }
  @media only screen and (max-width: 600px) {
    .body {
      padding-top: 6rem;
    }
  }
</style>
