<template>
  <div class="all-insurance">
    <div class="begin w-full flex justify-center items-center py-8">
      <div class="debt flex flex-col justify-center w-3/4">
        <div
          class="bloc rounded-3xl pt-8 px-10"
          data-aos="fade-up"
          data-aos-once="true"
          data-aos-duration="1500"
          data-aos-anchor-placement="top-center"
        >
          <div
            class="sous-bloc flex items-center leading-relaxed"
            data-aos="zoom-in"
            data-aos-once="true"
            data-aos-duration="1500"
          >
            <div class="sous-bloc1 w-1/2"></div>
            <div class="sous-bloc2 w-1/2">
              <div class="title text-black font-semibold text-4xl">{{ $t('insuranceInPocket') }}</div>
              <div class="description text-xl text-black my-8">{{ $t('downloadApp') }}</div>
              <div class="icons flex items-center">
                <svg-icon
                  name="ic_play_store"
                  original
                  class="w-48 mr-3 cursor-pointer"
                  @click="openGooglePlay"
                />
                <svg-icon
                  name="ic_app_store"
                  original
                  class="w-48 ml-3 cursor-pointer"
                  @click="openAppleStore"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="bloc-2 my-10 leading-relaxed"
          data-aos="fade-up"
          data-aos-once="true"
          data-aos-duration="1500"
          data-aos-anchor-placement="top-center"
        >
          <div class="bloc2-title text-4xl text-center text-black font-bold leading-relaxed mb-4">
            {{ $t('declareSinister') }}
          </div>
          <div class="sinister-content rounded-3xl py-16">
            <div class="bloc2-description text-center text-2xl text-black mb-6" v-html="$t('connectYourself')"></div>
            <div class="bouton">
              <button-base
                :label="$t('loginHeader')"
                :show-icon="true"
                name-icon="ic_user"
                @click.native="openUrl"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonBase from '../add/button-base'
export default {
  name: 'all-insurance',
  components: { ButtonBase },
  methods: {
    openUrl () {
      window.open('https://app.aab.bj/', '_blank')
    },
    openGooglePlay () {
      window.open('https://play.google.com/store/apps/details?id=com.devs41.oremi', '_blank')
    },
    openAppleStore () {
      window.open('https://apps.apple.com/bj/app/oremi/id1608579815?l=fr', '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .bloc {
    background: $aab_orange_background;
    border-radius: 42px;
  }
  .sous-bloc {
    // width: 50rem;
    height: 25rem;
    background-image: url("../../../assets/images/img_phone.webp");
    background-position: left;
    background-position-x: 5rem;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .bouton::v-deep {
    .button-base {
      text-align: -webkit-center;
      text-align: -moz-center;
      .page-button-real {
        height: 4rem;
        h4 {
          font-size: 1.25rem;
        }
      }
    }
  }
  .sinister-content {
    background: #F5F5F5;
  }
  @media only screen and (max-width: 424px) {
    .sous-bloc {
      // flex-wrap: wrap;
      justify-content: center;
      height: 50rem;
      background-position: bottom;
      display: block;
    }
  }
  @media only screen and (min-width: 425px) and (max-width: 600px) {
    .sous-bloc {
      // flex-wrap: wrap;
      justify-content: center;
      height: 58rem;
      background-position: bottom;
      display: block;
    }
  }
  @media only screen and (max-width: 600px) {
    .debt, .sous-bloc1, .sous-bloc2 {
      width: 100%;
    }
    /* .sous-bloc {
      // flex-wrap: wrap;
      justify-content: center;
      height: 47rem;
      background-position: bottom;
      display: block;
    } */
    .sous-bloc1 {
      display: none;
    }
    .sous-bloc2 {
      text-align: center;
      text-align: -moz-center;
      text-align: -webkit-center;
    }
    .icons {
      justify-content: center;
    }
  }
  @media only screen and (min-width: 601px) and (max-width: 768px) {
    .debt, .sous-bloc1, .sous-bloc2 {
      width: 100%;
    }
    .sous-bloc {
      // flex-wrap: wrap;
      justify-content: center;
      height: 64rem;
      background-position: bottom;
      display: block;
    }
    .sous-bloc1 {
      display: none;
    }
    .sous-bloc2 {
      text-align: center;
      text-align: -moz-center;
      text-align: -webkit-center;
    }
    .icons {
      justify-content: center;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .debt, .sous-bloc1, .sous-bloc2 {
      width: 100%;
    }
    .sous-bloc {
      // flex-wrap: wrap;
      justify-content: center;
      height: 64rem;
      background-position: bottom;
      display: block;
    }
    .sous-bloc1 {
      display: none;
    }
    .sous-bloc2 {
      text-align: center;
      text-align: -moz-center;
      text-align: -webkit-center;
    }
    .icons {
      justify-content: center;
    }
  }
  @media only screen and (max-width: 1024px) {
    .begin {
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }
  @media only screen and (min-width: 601px) and (max-width: 1024px) {
    .debt {
      width: 85%;
    }
  }
</style>
