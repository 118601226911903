<template>
  <div class="no-insurance">
    <div class="begin w-full h-screen flex justify-center items-center">
      <div
        class="flex flex-col justify-center"
        data-aos="zoom-in"
        data-aos-once="true"
        data-aos-duration="1500"
      >
        <div class="big text-5xl text-center font-medium text-white leading-relaxed mb-4">
          <span class="block">{{ $t('noInsurance') }}</span>
          <span class="block">{{ $t('noEngagement') }}</span>
        </div>
        <div class="bloc-list flex items-center mt-8">
          <div
            v-for="(item, index) in insurances"
            :key="index"
            class="blocus rounded-xl bg-white border-2 border-white mx-4 py-5 px-12 cursor-pointer"
            @click="goToLink(item.page)"
          >
            <div class="icons">
              <svg-icon
                :name="item.icon"
                original
                class="w-16 h-16"
              />
            </div>
            <div class="title text-center font-medium text-xl mt-4" v-html="item.title"></div>
          </div>

          <div
            class="blocus rounded-xl bg-white border-2 border-white mx-4 py-5 px-12 cursor-pointer"
            @click="goToHealth"
          >
            <div class="icons">
              <svg-icon
                name="ic_health"
                original
                class="w-16 h-16"
              />
            </div>
            <div class="title text-center font-medium text-xl mt-4" v-html="$t('healthInsurance')"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'no-insurance',
  data () {
    return {
      insurances: [
        {
          icon: 'ic_car',
          title: this.$t('autoInsurance'),
          page: 'auto',
          color: '#0269AD'
        },
        {
          icon: 'ic_motorcycle',
          title: this.$t('motoInsurance'),
          page: 'moto',
          color: '#49BF7C'
        },
        {
          icon: 'ic_airplane-mode',
          title: this.$t('travelInsurance'),
          page: 'voyage',
          color: '#FFC300'
        },
        {
          icon: 'ic_home',
          title: this.$t('habitationInsurance'),
          page: 'habitation',
          color: '#F44336'
        }
      ]
    }
  },
  methods: {
    goToLink (page) {
      this.$router.push({ path: '/' + page })
    },
    goToHealth () {
      // window.open('https://aps-benin.com/', '_blank')
      window.open('https://tarification.aps-benin.com/?code=100', '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .no-insurance {
    height: 100vh;
    background-image: url("../../../assets/images/img_woman.webp");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .icons {
    text-align: center;
    text-align: -moz-center;
    text-align: -webkit-center;
  }
  .svg-fill {
    fill: transparent;
  }
  .blocus {
    &:hover {
      color: $exemple;
      border: 2px solid #0269AD;
      .svg-fill::v-deep {
        fill: #0269AD;
        path {
          fill: #0269AD;
        }
      }
    }
  }
  .blocus:last-child {
    &:hover {
      color: #AEC340;
      border: 2px solid #AEC340;
      .svg-fill::v-deep {
        fill: #AEC340;
        path {
          fill: #AEC340;
        }
      }
    }
  }
  @media only screen and (max-width: 395px) {
    .no-insurance {
      height: 250vh;
      padding-top: 30rem;
    }
  }
  @media screen and (width: 375px) and (height: 812px) {
    .no-insurance {
      height: 180vh;
      padding-top: 30rem;
    }
  }
  @media only screen and (min-width: 396px) and (max-width: 424px) {
    .no-insurance {
      height: 200vh;
      padding-top: 30rem;
    }
  }
  @media only screen and (min-width: 425px) and (max-width: 600px) {
    .no-insurance {
      height: 200vh;
      padding-top: 20rem;
    }
  }
  @media only screen and (max-width: 600px) {
    .bloc-list {
      flex-wrap: wrap;
      justify-content: center;
    }
    .big {
      padding-right: 1rem;
      padding-left: 1rem;
      span {
        display: inline;
      }
    }
    .blocus {
      margin-bottom: 1.5rem;
      &:last-child {
        // width: 80%;
      }
    }
  }
  @media only screen and (min-width: 601px) and (max-width: 768px) {
    .bloc-list {
      flex-wrap: wrap;
      justify-content: center;
    }
    .big {
      padding-right: 1rem;
      padding-left: 1rem;
      span {
        display: inline;
      }
    }
    .blocus {
      margin-bottom: 1.5rem;
      &:last-child {
        // width: 80%;
      }
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .bloc-list {
      flex-wrap: wrap;
      justify-content: center;
    }
    .big {
      padding-right: 1rem;
      padding-left: 1rem;
      span {
        display: inline;
      }
    }
    .blocus {
      margin-bottom: 1.5rem;
      &:last-child {
        // width: 80%;
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    .begin {
      // padding-right: 1rem;
      // padding-left: 1rem;
      // height: auto;
    }
  }
</style>
